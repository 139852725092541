exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-portfolio-js": () => import("./../../../src/pages/en/portfolio.js" /* webpackChunkName: "component---src-pages-en-portfolio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-categoria-js": () => import("./../../../src/templates/Categoria.js" /* webpackChunkName: "component---src-templates-categoria-js" */),
  "component---src-templates-en-categoria-js": () => import("./../../../src/templates/en/Categoria.js" /* webpackChunkName: "component---src-templates-en-categoria-js" */),
  "component---src-templates-en-industria-js": () => import("./../../../src/templates/en/Industria.js" /* webpackChunkName: "component---src-templates-en-industria-js" */),
  "component---src-templates-en-project-js": () => import("./../../../src/templates/en/Project.js" /* webpackChunkName: "component---src-templates-en-project-js" */),
  "component---src-templates-industria-js": () => import("./../../../src/templates/Industria.js" /* webpackChunkName: "component---src-templates-industria-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

